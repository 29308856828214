<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 13:52:23
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:41:17
 * @FilePath: \dataview-next\src\manage-views\auth\sysAuth\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class='auth-container'>
    <AuthManage @row-click='rowClick' ref='AuthManage'></AuthManage>
    <AuthList ref='AuthList' :roleData='roleData' @refresh='refresh'></AuthList>
  </div>
</template>
<script >
import AuthManage from './components/AuthManage'
import AuthList from './components/AuthList'
export default {
  data() {
    return {
      roleData:null
    }
  },
  components: { 
    AuthManage,
    AuthList
  },
  methods: {
    rowClick(row){
      this.roleData = row;
    },
    refresh(){
      this.$refs.AuthManage.getData();
    }
  }
};
</script>
<style lang='less'>
.auth-container{
  background-color:#f6f8f9;
  width:100%;
  display: flex;
  height:100%;
}
</style>