<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 17:27:24
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:41:47
 * @FilePath: \dataview-next\src\manage-views\auth\sysAuth\components\AuthList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="auth-list" v-loading='loading'>
    <el-tabs v-model="activeName" >
      <el-tab-pane label="菜单权限" name="first">
        <div  v-show='roleData' class="main-box">
          <div class="main-title">
            <div>
              菜单权限
              <el-checkbox v-model="isExpand" size="medium" class="list-checkbox" @change ='onExpand'>展开/折叠</el-checkbox>
              <el-checkbox v-model="isCheckAll" size="medium" @change ='onCheckAll'>全选/全不选</el-checkbox>
              <!-- 父子联动现阶段不要 -->
              <!-- <el-checkbox v-model="isCheckStrictly" size="medium">父子联动</el-checkbox> -->
            </div>
            <div>
              <el-button size="mini" type="primary" @click="onSave" v-if="judgingAPermission(['sysAuth.update'])">保存</el-button>
            </div>
          </div>
          <div class="main-tree">
            <el-tree
              ref="tree"
              :data="menuData"
              show-checkbox
              node-key="id"
              :props="defaultProps">
            </el-tree>
          </div>
        </div>

        <!-- <div v-show='isSuper'>
          <div class="result-icon">
            <i class="iconfont iconwz_yiwancheng icon-class"></i>
          </div>
          <div class="result-title">超级管理员权限</div>
          <div class="result-subtitle">超级管理员不受权限控制,其余角色根据需求设置菜单,小页以及数据权限</div>
        </div> -->
      </el-tab-pane>
      <!-- 暂时没有数据权限 可能后期会加上 -->
      <!-- <el-tab-pane label="数据权限" name="second">数据权限</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import { judgingAPermission } from '@/utils/tools'
import {TabPane, Tabs, Tree} from 'element-ui'
import { menuOperation } from '@/api/saas'
import { getMenuSelect } from '@/api/saasManage'
export default {
  name:'AuthList',
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-tree':Tree
  },
  props: {
    roleData:{
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      judgingAPermission: judgingAPermission,
      isSuper:false, // 判断是否为超级管理员
      activeName: 'first',
      checked:'',
      isExpand:false, // 是否全部展开
      isCheckAll:false, // 是否全选
      // isCheckStrictly: false, // 父子是否联动
      menuData:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleDatas:null,
      loading:false,
    };
  },
  watch: {
    roleData(newVal){
      this.loading = true;
      // 每次进来把选中清空
      this.$refs.tree.setCheckedKeys([]);
      this.roleDatas = []
      if(newVal){
        this.isSuper = newVal.is_super;
        this.roleDatas = newVal;
        if(newVal.role_menu && newVal.role_menu.length){
          let checkKeys = [];
          newVal.role_menu.forEach((item) => {
            if(item.parameter!=='0'){
              checkKeys.push(item.menu_id);
            }
          })
          this.$refs.tree.setCheckedKeys(checkKeys);
        }
      }
      this.loading = false;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * @desc:保存
     */
    onSave(){
      this.loading = true;
      let checkNodes = this.$refs.tree.getCheckedNodes(); //全选的数据
      let halfNodes = this.$refs.tree.getHalfCheckedNodes(); //半选的数据
      let arr = [];
      checkNodes.forEach((item)=>{
        if(!item.children.length){
          arr.push({menu_id:item.id, parameter:true});
        }else{
          arr.push({menu_id:item.id, parameter:false});
        }
      })
      halfNodes.forEach((item)=>{
        arr.push({menu_id:item.id, parameter:false});
      })
      let params = {
        id:this.roleDatas.id,
        role_menu:arr,
      };
      menuOperation(params).then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.$emit('refresh');
        this.isCheckAll = false;
        this.isExpand = false;  
        this.loading = false;
        this.$message.success(`操作成功`);
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
    /**
     * @desc: 展开折叠事件
     */
    onExpand(){
      if(this.isExpand){
        for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
          this.$refs.tree.store._getAllNodes()[i].expanded = true;
        }
      }else{
        for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
          this.$refs.tree.store._getAllNodes()[i].expanded = false;
        }
      }
    },
    onCheckAll(){
      if (this.isCheckAll) { // 全选
        this.$nextTick(() => {    //这个如果要默认全选就必须加，否则会报错“setCheckedNodes”未定义
          this.$refs.tree.setCheckedNodes(this.menuData);
        })
        // this.
      } else { // 取消选中
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        })
      }
    },
    getData(){
      getMenuSelect(1).then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.menuData = res.data.data;
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    }
  },
};
</script>
<style lang="less">
.auth-list{
  background: #fff;
  margin-left: 8px;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  .el-tabs{
    height:100%;
    .el-tabs__content {
      height: calc( 100% - 55px ) !important;
    }
  }
  .main-box{ 
    height: 100%;
    border:1px solid #eee;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .main-title{
      display: flex;
      min-height: 48px;
      margin-bottom: -1px;
      padding: 0 24px;
      color: rgba(0,0,0,.85);
      font-weight: 500;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid #e8e8e8;
      align-items: center;
      border-radius: 2px 2px 0 0;
      zoom: 1;
      justify-content: space-between;
    }
    .main-tree{
      flex: 1;
      overflow: auto;
    }
  }
  .list-checkbox{
    margin-left: 20px;
  }
}
.el-tab-pane {
  height:100%;
}
.result-icon{
  display:flex;
  justify-content: space-around;
  .icon-class{
    font-size:50px;
    color:#52c41a;
  }
}
.result-title{
  color: rgba(0,0,0,.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.result-subtitle{
  color: rgba(0,0,0,.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
</style>