<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 14:13:33
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:41:32
 * @FilePath: \dataview-next\src\manage-views\auth\sysAuth\components\AuthManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sys-main" >
    <div class='main-title'>
      <div class="main-label">菜单授权</div>
      <div class="search">
        <el-input placeholder="请输入名称" v-model="searchName" class="search-input" @input="onRefresh"></el-input>
      </div>
    </div>
    <div class="sys-table">
      <el-table
      ref="table"
      :data="sysData"
      highlight-current-row 
      :header-cell-style="{background:'#fafafa'}"
      v-loading='loading' 
      node-key= 'id'
      @row-click='roleClick'
      >
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="code" label="角色编码"></el-table-column>
      </el-table>
    </div>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getSysAuth } from '@/api/saasManage'
export default {
  name:"AuthManage",
  data() {
    return {
      searchName: "", // 筛选的name
      loading: false,
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      sysData:null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * @desc:重置筛选
     */
    onRefresh(){
      this.searchName = '';
      this.getData();
    },
    /**
     * @desc：table点击行事件
     */
    roleClick(row){
      this.$refs.table.setCurrentRow(row);
      this.$emit('row-click',row);
    },
    /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.searchName
      };
      getSysAuth(params).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.sysData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },

};
</script>
<style lang="less">
@import "../../../../css/manageMain.less";
.sys-main {
  width: 50%;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .search{
    display:flex;
    .search-input{
      width:150px;
      margin-right:20px
    }
  }
  .sys-table{
    padding-left: 11px;
    padding-right: 16px;
    flex: 1;
    overflow: auto;
  }
}
</style>